@import './../../consts/LAYOUT.scss';

.container {
    position: fixed;
    bottom: 40px;
    margin-right: 20px;
    margin-left: 20px;
    align-self: 'center';
    width: 700px;
    background-color: $white;
    padding: 10px 20px;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    z-index: 100;
    transition: width 0.5s;

    @media screen and (max-width: 700px) {
        width: 100%;
    }
}

.title {
    font-size: 15px;
    font-weight: 600;
}

.subtitle {
    font-size: 13px;
}

.button {
    background-color: $primary;
    color: $white;
    width: 80px;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: 600;
}