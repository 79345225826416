@import './../consts/LAYOUT.scss';

.screenContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 400px;
    transition: width 0.5s;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}

.formContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.legalDocs {
    color: $white;
}

.menuItem {
    margin-bottom: 2px;
    // text-decoration: underline;
    color: $accent;
}

.link {
    font-size: 15px;
    // margin-bottom: 5px;
    text-decoration: underline;
    color: $accent;
}