@import './../../consts/LAYOUT.scss';

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
    transition: width 0.5s;
    align-items: center;

    @media screen and (max-width: 600px) {
        width: 100%;
    }

}

.imageContainer {
    width: 100%;
    aspect-ratio: 297/210;
    border-radius: 10px;
    position: relative;
    background-color: $white;
}

.image {
    width: 100%;
    aspect-ratio: 297/210;
    border-radius: 10px;
}

.placeholderContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    aspect-ratio: 297/210;
    background-color: $white;
    border-radius: 10px;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
    white-space: pre-wrap;
}

.placeholder {
    text-align: center;
    font-weight: 600;
    color: $placeholder;
}

.input {
    display: none;
}

.inputLabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top: none;
    border: 2px solid $light-grey;
    background-color: $primary;
    border-radius: 10px;
    height: 46px;
    width: 120px;
    color: $white;
}

.editInputLabel {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 80px;
    background-color: $primary;
    color: $white;
    padding: 5px;
    border-radius: 10px;
    position: absolute;
    bottom: 15px;
    right: 15px;
    cursor: pointer;
}

.error {
    align-self: flex-start;
    color: $warning;
    font-size: 12px;
}