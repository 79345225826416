@import '../../consts/LAYOUT.scss';

.button {
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $primary;
    color: $white;
    font-weight: bold;
    border-radius: 38px;
    cursor: pointer;
}