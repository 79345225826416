@import './../../consts/LAYOUT.scss';

.container {
    height: 60px;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
    padding-left: 15px;
    background-color: #000000;
}

.spacer {
    width: 150px;
}

.logoutButton {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: $primary;
    font-size: 20px;
    height: 36px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 20px;
}

.rightIconContainer {
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    width: 150px;
}

.rightContainer {
    position: absolute;
    right: 0px;
    top: 40px;
    border-radius: 10px;
    padding: 20px;
    background-color: $white;
}


.appStoreIcon {
    color: $accent;
    font-size: 30px;
    margin-left: 10px;
    margin-right: 10px;
}

.playStoreIcon {
    color: $accent;
    font-size: 30px;
    margin-left: 10px;
    margin-right: 10px;
}

.userIcon {
    color: $accent;
    font-size: 30px;
    margin-left: 10px;
    margin-right: 10px;
}


.email {
    margin-bottom: 20px;
    color: $primary-dark;
    font-weight: 600;
}

.menuTitle {
    font-size: 16px;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 5px;
}

.menuItem {
    font-size: 14px;
    margin-bottom: 5px;
    text-decoration: underline;
    color: $primary;
}

.iosQrCodeContainer {
    width: 220px;
    background-color: $white;
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    right: 80px;
    top: 40px;
    text-align: center;
}

.androidQrCodeContainer {
    width: 220px;
    background-color: $white;
    border-radius: 10px;
    padding: 10px;
    position: absolute;
    right: 40px;
    top: 40px;
    text-align: center;
}

.qrCode {
    width: 200px;
    height: 200px;
}

.qrCodeText {
    font-size: 14px;
    font-weight: 600;
}