@import "./../../consts/LAYOUT.scss";

.container {
    background-color: $light-grey;
    border-radius: $radius-medium;
    margin-bottom: $space-medium;
    padding: $space-small $space-large;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.selectedContainer{
    background-color: $primary30;
    border-radius: $radius-medium;
    margin-bottom: $space-medium;
    padding: $space-small $space-large;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.title{
    font-size: $font-medium;
}

.icon{
    font-size: $font-large;
    color: $primary;
}

.foundText{
    font-size: $font-medium;
    font-weight: bold;
    color: $primary;
}