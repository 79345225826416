@import "../../consts/LAYOUT.scss";

.container {
    align-items: center;
    border-radius: $radius-medium;
    padding: $space-medium;
    background-color: $white;
    top: $space-medium;
    right: 0;
    left: 0;
    position: absolute;
    display: flex;
    flex-direction: row;
}

.iconContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    font-size: 20px;
    line-height: 0;
    color: $warning;
    margin-right: $space-small;
}

.visible {
    opacity: 1;
    transition: opacity 0.5s;
}

.hidden {
    opacity: 0;
    // transition: opacity 0.5s;
}