@import "./../consts/LAYOUT.scss";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 80px;
}

.publishButton {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: $green;
    width: 450px;
    height: 56px;
    font-size: 20px;
    border-radius: 28px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    margin-top: 30px;
    transition: width 0.5s;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}

.unpublishButton {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: $warningDark;
    width: 450px;
    height: 56px;
    font-size: 20px;
    border-radius: 28px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    margin-top: 30px;
    transition: width 0.5s;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}