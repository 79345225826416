.center {
  justify-content: center;
  align-items: center;
}

.hCenter {
  justify-content: center;
}

.vCenter {
  align-items: center;
}

.spaceBetween {
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.selfCenter {
  align-self: center;
}

.selfStart {
  align-self: flex-start;
}

.selfEnd {
  align-self: flex-end;
}

.overflowHidden {
  overflow: hidden;
}

.flexWrap {
  display: flex;
  flex-wrap: wrap;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.disabled {
  opacity: 0.5;
}

.flex {
  flex: 1;
}



// hCenter && { justifyContent: 'center' },
// vCenter && { alignItems: 'center' },
// selfCenter && { alignSelf: 'center' },
// spaceBetween && { justifyContent: 'space-between' },
// row && { flexDirection: 'row' },
// !!alignItems && { alignItems },
// !!alignSelf && { alignSelf },
// !!overflow && { overflow },
// !!absolute && { position: 'absolute' },
// !!disabled && { opacity: 0.5 },


// !!justifyContent && { justifyContent },
// !!flex && { flex: flex === true ? 1 : flex },
// !!flexGrow && { flexGrow: flexGrow === true ? 1 : flexGrow },
// !!flexWrap && { flexWrap },
// !!mAll && { margin: mAll },
// !!mt && { marginTop: mt },
// !!mb && { marginBottom: mb },
// !!ml && { marginLeft: ml },
// !!mr && { marginRight: mr },
// !!mh && { marginHorizontal: mh },
// !!mv && { marginVertical: mv },
// !!width && { width },
// !!minWidth && { minWidth },
// !!height && { height },
// !!minHeight && { minHeight },
// !!pAll && { padding: pAll },
// !!ph && { paddingHorizontal: ph },
// !!pv && { paddingVertical: pv },
// !!pl && { paddingLeft: pl },
// !!pt && { paddingTop: pt },
// !!pr && { paddingRight: pr },
// !!pb && { paddingBottom: pb },
// !!backgroundColor && { backgroundColor },
// !!aspectRatio && { aspectRatio },
// !!borderWidth && { borderWidth },
// !!borderStyle && { borderStyle },
// !!borderBottomWidth && { borderBottomWidth },
// !!borderColor && { borderColor },
// !!borderRadius && { borderRadius },
// !!borderBottomLeftRadius && { borderBottomLeftRadius },
// !!borderBottomRightRadius && { borderBottomRightRadius },
// !!borderTopLeftRadius && { borderTopLeftRadius },
// !!borderTopRightRadius && { borderTopRightRadius },
// (!!zIndex || (zIndex === 0)) && { zIndex },
// (!!top || (top === 0)) && { top },
// (!!bottom || (bottom === 0)) && { bottom },
// (!!left || (left === 0)) && { left },
// (!!right || (right === 0)) && { right },
// style,