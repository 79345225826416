@import './../../consts/LAYOUT.scss';

.container {
    width: 100vw;
    position: fixed;
    bottom: 0;
    z-index: 2;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-right: 15px;
    padding-left: 15px;
}

.saveButton {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: $primary;
    width: 450px;
    height: 56px;
    font-size: 20px;
    border-radius: 28px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 30px;
    transition: width 0.5s;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}