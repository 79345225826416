@import './../../consts/LAYOUT.scss';

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    padding: 20px;
}

.container {
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    padding-right: 0px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 450px;
    transition: width 0.5s;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}

.closeIconContainer {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
    cursor: pointer;
}

.closeIcon {
    color: $black;
    // color: red;
    font-size: 1.5rem;
}

.title {
    color: $black;
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

.subtitle {
    color: $black;
    font-size: 16px;
    overflow-y: scroll;
    max-height: 500px;
    padding-right: 20px;
}