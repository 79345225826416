@import './../../consts/LAYOUT.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
    transition: width 0.5s;

    @media screen and (max-width: 600px) {
        width: 100%;
    }

}

.hoverContainer {
    margin-bottom: 10px;
    border-radius: 5px;
    background-color: $white;
}

.hoverContainer:hover {
    border: 3px solid $primary;
    margin-bottom: 4px;
}

.button {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    padding-right: $space-medium;
}

.textContainer {
    flex: 1;
}

.text {
    width: 100%;
    height: 44px;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
}

.placeholder {
    width: 100%;
    height: 44px;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
    color: $placeholder;
}

.icon {
    color: $black;
    font-size: 18px;
}

.error {
    margin-top: -10px;
    color: $warning;
    font-size: 12px;
}