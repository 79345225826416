@import './../../consts/LAYOUT.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
    transition: width 0.5s;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}

.inputContainer {
    display: flex;
    position: relative;
    margin-bottom: 10px;
    align-items: center;

    :hover {
        border: 3px solid $primary;
    }

    :focus-within {
        border: 3px solid $accent;
    }
}

.calendarIcon {
    position: absolute;
    color: $black;
    font-size: 1.3rem;
    right: 10px;
    pointer-events: none;
}

.unit {
    display: flex;
    font-size: $font-small;
    background-color: $white;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    width: 50px;
    height: 44px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    cursor: pointer;

    :hover {
        border: 3px solid $primary;
    }

    :focus-within {
        border: 3px solid $accent;
    }
}

.input {
    width: 100%;
    height: 44px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;

    ::placeholder {
        color: $placeholder;
    }
}

.charLimit {
    font-size: 0.8rem;
    text-align: end;
    color: $white;
}

.charLimitReached {
    font-size: 0.8rem;
    text-align: end;
    color: $warning;
}

.bottomContainer {
    margin-top: -10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.error {
    color: $warning;
    font-size: 12px;
}