@import '../../consts/LAYOUT.scss';

.container {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    // border: 1px solid red;
    margin-bottom: 30px;
    justify-content: space-evenly;
    align-items: center;
}

.tab {
    padding: 10px 10px 0px 10px;
    cursor: pointer;
    // border: 1px solid blue;
}

.underline {
    height: 3px;
    background-color: $accent;
    border-radius: 2px;
}