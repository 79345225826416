// backgrund container that should cover the entire page
.container {
    position: fixed;
    top: -200px;
    left: -200;
    right: -200;
    height: 200%;
    z-index: -1;
    background-color: #000000;

}

.row1 {
    height: 300px;
    aspect-ratio: 18.892;
    object-fit: cover;
    opacity: 0.5;
    margin-bottom: 40px;
    overflow: visible;
    transform: rotate3d(1, 1, 1, -35deg) translateX(700px);
    animation: slideInRight 35s infinite alternate; // Apply the animation to the row class
}

.row2 {
    height: 300px;
    aspect-ratio: 18.892;
    object-fit: cover;
    opacity: 0.2;
    margin-bottom: 40px;
    overflow: visible;
    transform: rotate3d(1, 1, 1, -35deg) translateX(-500px);
    animation: slideInLeft 90s infinite alternate; // Apply the animation to the row class
}

.row3 {
    height: 300px;
    aspect-ratio: 18.892;
    object-fit: cover;
    opacity: 0.1;
    margin-bottom: 40px;
    overflow: visible;
    transform: rotate3d(1, 1, 1, -35deg) translateX(500px);
    animation: slideInRight 35s infinite alternate; // Apply the animation to the row class
}

.row4 {
    height: 300px;
    aspect-ratio: 18.892;
    object-fit: cover;
    opacity: 0.5;
    margin-bottom: 40px;
    overflow: visible;
    transform: rotate3d(1, 1, 1, -35deg) translateX(-500px);
    animation: slideInLeft 40s infinite alternate; // Apply the animation to the row class
}

.row5 {
    height: 300px;
    aspect-ratio: 18.892;
    object-fit: cover;
    opacity: 0.1;
    margin-bottom: 40px;
    overflow: visible;
    transform: rotate3d(1, 1, 1, -35deg) translateX(500px);
    animation: slideInRight 70s infinite alternate; // Apply the animation to the row class
}


.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

@keyframes slideInRight {
    0% {
        transform: rotate3d(1, 1, 1, -35deg) translateX(500px); // Start with rotation and translation
    }

    100% {
        transform: rotate3d(1, 1, 1, -35deg) translateX(-500px); // End with rotation and translation
    }
}

@keyframes slideInLeft {
    0% {
        transform: rotate3d(1, 1, 1, -35deg) translateX(-500px); // Start with rotation and translation
    }

    100% {
        transform: rotate3d(1, 1, 1, -35deg) translateX(500px); // End with rotation and translation
    }
}