@import './../../consts/LAYOUT.scss';

.titleContainer {
    display:flex;
    align-self: flex-start;
    flex: 0;
    flex-direction: row;
    margin: 10px 0;
}

.infoIcon {
    color: $white;
    font-size: 1.3rem;
    margin-top: 10px;
    margin-left: 10px;
}