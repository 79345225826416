@import "./../consts/LAYOUT.scss";

.container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    z-index: 1000;
}

.animatedImage {
    width: 100px;
    height: 100px;
    transform: scale(500);

    @keyframes zoomOut {
        0% {
            transform: scale(500);
        }

        100% {
            transform: scale(1);
        }
    }

    animation: zoomOut 1.3s forwards;
}

.image {
    width: 100px;
    height: 100px;
}

.text {
    margin-left: 15px;
    font-weight: 500;
    font-size: 18px;
    color: $accent; // Ensure $accent is defined in the imported file
}