@import '../../consts/LAYOUT.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
    transition: width 0.5s;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}

.contentContainer {
    display: flex;
    position: relative;
    margin-bottom: 10px;
    align-items: center;
    flex-direction: row;
    background-color: $white;
    border-radius: 5px;
    cursor: pointer;
    padding-right: $space-medium;
}

.conditionLabel {
    width: 100%;
    height: 44px;
    padding: 10px;
    border-radius: 5px;
    font-size: 16px;
}

.checkIcon {
    color: $primary;
    font-size: 24px;
}

.textInputContainer {
    margin-top: -20px;
    width: 100%;
    height: 65px;
    padding: 10px;
    background-color: $white;
    border-radius: 5px;
    font-size: 16px;
}

.textInput {
    width: 100%;
    padding: 10px;
    background-color: $white;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.error {
    align-self: flex-start;
    color: $warning;
    font-size: 12px;
}