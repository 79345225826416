@import './../../consts/LAYOUT.scss';

.container {
    background-color: #000000CC;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    display: flex;
    padding-top: 100px;
    justify-content: center;
    align-items: flex-start;
    z-index: 2;
}

.content {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 450px;
    padding: 20px;
    border-radius: 10px;
    background-color: $white;
    cursor: pointer;
}

.successIcon {
    color: $green;
    font-size: 30px;
}

.warningIcon {
    color: $accent;
    font-size: 30px;
}

.errorIcon {
    color: $warning;
    font-size: 30px;
}

.message {
    margin-left: 10px;
    color: $black;
    font-size: 14px;
    font-weight: 600;
}