@import './../../consts/LAYOUT.scss';

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    padding: 20px;
}

.container {
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 450px;
    transition: width 0.5s;


    @media screen and (max-width: 600px) {
        width: 100%;
    }
}

.closeIconContainer {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
    cursor: pointer;
}

.closeIcon {
    color: $black;
    font-size: 1.5rem;
}

.title {
    color: $black;
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: bold;
}

.subtitle {
    color: $black;
    font-size: 14px;
    overflow-y: scroll;
    max-height: 500px;
    padding-right: 20px;
}

.input {
    height: 40px;
    padding: 10px;
    border-width: 1px;
    border-radius: 10;
    width: 100%;
    font-size: 14px;
    margin-top: 5px;
}

.unpublishButton {
    // margin-left: 10px;
    // margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: $warningDark;
    // width: 500px;
    height: 56px;
    font-size: 20px;
    border-radius: 28px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    margin-top: 30px;
    transition: width 0.5s;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}

.unpublishButtonDisabled {
    // margin-left: 10px;
    // margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    background-color: $grey;
    // width: 500px;
    height: 56px;
    font-size: 20px;
    border-radius: 28px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    margin-top: 30px;
    transition: width 0.5s;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}