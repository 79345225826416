@import './../../consts/LAYOUT.scss';

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    padding: 20px;
}

.container {
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    width: 450px;
    transition: width 0.5s;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}

.closeIconContainer {
    position: absolute;
    top: 0px;
    right: 0px;
    padding: 10px;
    cursor: pointer;
}

.closeIcon {
    color: $black;
    // color: red;
    font-size: 1.5rem;
}

.titleContainer {
    display: flex;
    flex-direction: row;
    // justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.title {
    color: $black;
    font-size: 20px;
    margin-right: 20px;
    font-weight: bold;
}

.pickerMaxOptions {
    font-size: $font-small;
}

.searchInput {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid $primary;
    border-radius: 5px;
}

.selectedValuesContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: $space-medium;
}

.selectedValue {
    background-color: $light-grey;
    padding: $space-xsmall $space-small;
    margin-bottom: $space-xsmall;
    margin-right: $space-xsmall;
    flex-direction: row;
    display: flex;
    align-items: center;
    border-radius: $radius-small;
    cursor: pointer;
}

.selectedValueText {
    color: $primary;
    font-size: $font-small;
    font-weight: 600;
}

.selectedCloseIcon {
    color: $primary;
    font-size: 14px;
    margin-left: $space-small;
}

.scrollContent {
    margin-right: -20px;
    padding-right: 20px;
    max-height: 400px;
    overflow-y: auto;
}