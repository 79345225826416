@import './../../consts/LAYOUT.scss';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
    transition: width 0.5s;

    @media screen and (max-width: 600px) {
        width: 100%;
    }
}

.inputContainer {
    display: flex;
    position: relative;
    margin-bottom: 10px;
    align-items: center;

    :hover {
        border: 3px solid $primary;
    }

    :focus-within {
        border: 3px solid $accent;
    }
}

.calendarIcon {
    position: absolute;
    color: $black;
    font-size: 1.3rem;
    right: 10px;
    pointer-events: none;
}

.input {
    width: 100%;
    height: 44px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;

    ::placeholder {
        color: $placeholder;
    }
}

.datePickerContainer {
    position: relative;
}

.datePickerLabel {
    position: absolute;
    left: 10px;
    color: $black;

}

.placeholder {
    position: absolute;
    left: 10px;
    color: $placeholder;
}

.error {
    align-self: flex-start;
    color: $warning;
    font-size: 12px;
}