@import '../../consts/LAYOUT.scss';

.regular {
    font-weight: normal;
    font-size: $font-small;
}

.largeTitle {
    font-size: $font-xxlarge;
    font-weight: bold;
}

.title {
    font-size: $font-large;
    
    font-weight: bold;
}

.subtitle {
    font-weight: 600;
    font-size: $font-medium;
}

.white {
    color:$white;
}

.accent {
    color: $accent;
}

.primary{
    color: $primary;
}

.primaryDark{
    color: $primary-dark;
}
