@import '../../consts/LAYOUT.scss';

.container {
  width: 100%;
}

.authInputContainer{
  width: 100%;
  position: relative;
  align-items: center;
  display: flex;
  padding: 0
}

.authInput {
  width: 100%;
  height: 44px;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
}

.eyeContainer {
  position:absolute;
  right: 20px;
  color: $accent;
  font-size: 20px;
  line-height: 0;
}